import { useRef, useEffect, useCallback } from 'react';

const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const checker = useCallback(() => isMounted.current, []);
  return checker;
};

export default useIsMounted;
