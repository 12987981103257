import React, { ReactNode } from 'react';
import {
  IllustrationCloud,
  IllustrationError,
  VectorLogoMaiia,
} from '@maiia/design-system';
import { Typography, Button, Box } from '@mui/material';
import Link from 'next/link';
import clsx from 'clsx';
import { useTranslation } from '@/src/i18n';
import FullscreenContent from '@/components/atoms/FullscreenContent/FullscreenContent';
import { APP_ROUTES } from '@/src/constants';
import { isLocalstorageAllowed } from '@/src/utils';

type Props = {
  title?: string;
  message?: ReactNode;
  displayHomeButton?: boolean;
  displayMaiiaLogo?: boolean;
  disableHeaderHeightOffset?: boolean;
};

const ErrorPage = ({
  title,
  message,
  displayHomeButton = true,
  displayMaiiaLogo = false,
  disableHeaderHeightOffset,
}: Props) => {
  const { t } = useTranslation();
  return (
    <FullscreenContent
      disableHeaderHeightOffset={disableHeaderHeightOffset}
      sx={theme => ({
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        padding: 4,
        bgcolor: 'background.xLightBlue',
        overflowX: 'hidden',
        '.logo': {
          position: 'absolute',
          top: theme.spacing(4),
          left: '50%',
          transform: 'translate(-50%)',
          width: '115px',
          height: '38px',
        },
        ' > *': {
          zIndex: 10,
        },
        '.illustration-error': {
          margin: '0 auto',
          width: '263px',
          [theme.breakpoints.down('md')]: {
            maxWidth: '70%',
          },
        },
        '.illustration-cloud': {
          [theme.breakpoints.down('md')]: {
            width: '60%',
          },
        },
        '.illustration-cloud-1': {
          position: 'absolute',
          top: '100px',
          left: '100px',
          [theme.breakpoints.down('md')]: {
            top: '0px',
            left: '-50px',
          },
        },
        '.illustration-cloud-2': {
          position: 'absolute',
          bottom: '200px',
          left: '-50px',
          [theme.breakpoints.down('md')]: {
            bottom: '30px',
            left: '-100px',
          },
        },
        '.illustration-cloud-3': {
          position: 'absolute',
          top: '200px',
          right: '100px',
          [theme.breakpoints.down('md')]: {
            top: '50px',
            right: '-100px',
          },
        },
        '.illustration-cloud-4': {
          position: 'absolute',
          bottom: '100px',
          right: '-50px',
          [theme.breakpoints.down('md')]: {
            bottom: '10px',
            right: '-100px',
          },
        },
      })}
    >
      <IllustrationCloud
        className={clsx('illustration-cloud', 'illustration-cloud-1')}
      />
      <IllustrationCloud
        className={clsx('illustration-cloud', 'illustration-cloud-2')}
      />
      <IllustrationCloud
        className={clsx('illustration-cloud', 'illustration-cloud-3')}
      />
      <IllustrationCloud
        className={clsx('illustration-cloud', 'illustration-cloud-4')}
      />
      {displayMaiiaLogo && <VectorLogoMaiia className="logo" />}
      <IllustrationError className="illustration-error" />
      <Typography color="info" variant="h4" component="h2" mt={4}>
        {title && title.length > 0 ? title : t('title_page_error')}
      </Typography>
      {message}
      {!isLocalstorageAllowed() && (
        <Typography>{t('localstorage_disabled_error')}</Typography>
      )}
      {displayHomeButton && (
        <Box mt={6}>
          <Link href={APP_ROUTES.HOME} legacyBehavior>
            <Button
              variant="contained"
              color="secondary"
              data-cy="btn-error-go-home"
            >
              {t('go_to_homepage')}
            </Button>
          </Link>
        </Box>
      )}
    </FullscreenContent>
  );
};

export default ErrorPage;
