import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import {
  IconChevronLeft,
  IconPerson,
  VectorLogoMaiiaMonochrome,
} from '@maiia/design-system';
import useBranding from '@/src/hooks/branding/useBranding';
import Link from '../../atoms/Link/Link';

import { APP_ROUTES } from '@/src/constants';

const GoToAgendaButton = styled(Button, { shouldForwardProp: () => true })(
  ({ theme }) => css`
    color: ${theme.palette.secondary.main};
    background-color: ${theme.palette.common.white};

    &:hover {
      box-shadow: 0 0 6px 0 ${theme.palette.common.white};
      color: ${theme.palette.secondary.main};
      background-color: ${theme.palette.common.white};
    }
  `,
);

const HeaderPro = () => {
  const { data: branding } = useBranding();

  return (
    <>
      <div className="header header--pro">
        <div className="header__menu-toggle-logo">
          <Link href={APP_ROUTES.HOME} passHref legacyBehavior>
            <Stack direction="row" alignItems="center">
              <Button
                sx={{ minWidth: 0, px: 0 }}
                className="return-btn"
                data-cy="btn-header-return-home"
              >
                <IconChevronLeft />
              </Button>
              <div className="brandname">
                {branding?.name ? (
                  <span className="brandname--pharmacy">{branding?.name}</span>
                ) : (
                  <VectorLogoMaiiaMonochrome />
                )}
              </div>
            </Stack>
          </Link>
        </div>
        <Link href="https://maiiapro.page.link/home" legacyBehavior>
          <a target="_blank" rel="noreferrer">
            <GoToAgendaButton
              color="info"
              variant="contained"
              data-cy="login-pro"
              endIcon={<IconPerson />}
            >
              Accéder à mon agenda
            </GoToAgendaButton>
          </a>
        </Link>
      </div>
    </>
  );
};

export default HeaderPro;
