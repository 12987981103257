import { Button, cn, Separator } from '@kiiwi/ui';
import { Typography } from '@mui/material';
import * as React from 'react';
import { useCurrentUser } from '@docavenue/components';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from '@/src/i18n';
import Link from '@/components/atoms/Link/Link';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import useResponsive from '@/src/hooks/useResponsive';
import { APP_ROUTES } from '@/src/constants';

const Support = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const currentUser = useCurrentUser();
  const router = useRouter();
  const isBcb = router.pathname.includes(APP_ROUTES.MEDICAMENTS.BASE_URL);
  const bgColor = isBcb ? 'bg-[#4eb3c9]' : 'bg-surface-brand';

  const isFooterSupportSeparator = !currentUser && !isMobile;

  return (
    <div
      className={cn(
        'px-4 pt-6 text-center md:px-16 ',
        currentUser
          ? 'bg-[#e0f5f4ff] pb-6 text-neutral-primary'
          : `${bgColor} text-white`,
      )}
    >
      <Typography variant="h3" component="h2">
        {t('need-help')}
      </Typography>
      <Typography fontSize={16}>
        <Trans
          i18nKey="contact-our-support-center-or-contact-us-with-underline"
          components={{
            underline: (
              <Typography
                component="span"
                fontWeight={500}
                fontSize={16}
                sx={{ textDecoration: 'underline' }}
              />
            ),
          }}
        />
      </Typography>

      <Link
        href="https://maiia-guide.zendesk.com/hc/fr"
        passHref
        legacyBehavior
      >
        <Button
          className="mt-4"
          variant={currentUser ? 'secondary' : 'secondary-inverse'}
          size="md"
          onClick={() =>
            analyticsEvent({
              category: 'Footer',
              action: 'HelpFooter',
            })
          }
        >
          {t('help-menu')}
        </Button>
      </Link>
      {isFooterSupportSeparator && (
        <Separator className="mt-6 bg-surface-white" />
      )}
    </div>
  );
};

export default Support;
