import React from 'react';
import { useCurrentUser } from '@docavenue/components';
import { User } from '@maiia/model/generated/model/api-patient/api-patient';
import { useRouter } from 'next/router';
import useIsSncfUrl from '@/src/hooks/sncf/useIsSncfUrl';
import { APP_ROUTES } from '@/src/constants';
import { authActions } from '@/src/actions';

function disconnectUser() {
  localStorage.removeItem('tokenPat');
  localStorage.removeItem('refreshPat');
  localStorage.removeItem('key');
  localStorage.removeItem('keyId');
  authActions.setItem();
}

const SncfRedirectionHandler = () => {
  const currentUser = useCurrentUser<
    User & {
      token: string;
      refresh: string;
    }
  >();
  const isSncfUrl = useIsSncfUrl();
  const router = useRouter();

  // SNCF users must not access maiia.com and are redirected to sncf.maiia.com, and vice versa for maiia users.
  // The /login-with-token route allows the connection to persist between both subdomains.
  React.useEffect(() => {
    if (!currentUser) return;
    // Remove the first subdomain (either www., pat., sncf., etc.).
    const url = window.location.host.replace(/^[^.]+\./, '');

    const params = new URLSearchParams();
    params.append('tokenPat', currentUser.token);
    params.append('refreshPat', currentUser.refresh);
    const key = localStorage.getItem('key');
    const keyId = localStorage.getItem('keyId');
    if (key) params.append('key', key);
    if (keyId) params.append('keyId', keyId);

    if (
      currentUser.userPatientInformation?.sncfData?.sncfIdentifier &&
      !isSncfUrl
    ) {
      // Disconnect user from current website.
      disconnectUser();
      router.push({
        pathname: `${window.location.protocol}//sncf.${url}${APP_ROUTES.LOGIN_WITH_TOKEN}`,
        hash: params.toString(),
        query: {
          next: router.asPath,
        },
      });
    }
    if (
      !currentUser.userPatientInformation?.sncfData?.sncfIdentifier &&
      isSncfUrl
    ) {
      // Disconnect user from current website.
      disconnectUser();
      router.push({
        pathname: `${window.location.protocol}//www.${url}${APP_ROUTES.LOGIN_WITH_TOKEN}`,
        hash: params.toString(),
        query: {
          next: router.asPath,
        },
      });
    }
  }, [currentUser, isSncfUrl]);

  return null;
};

export default SncfRedirectionHandler;
