import React, { PropsWithChildren } from 'react';
import NextLink, { LinkProps } from 'next/link';

const Link: React.FC<PropsWithChildren<LinkProps>> = ({
  children,
  ...props
}) => (
  <NextLink {...props} legacyBehavior>
    {children}
  </NextLink>
);

export default Link;
